// Copyright 2015 The Oppia Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS-IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
/**
 * @fileoverview Directives for the outcome feedback editor.
 */
require('domain/exploration/SubtitledHtmlObjectFactory.ts');
require('domain/utilities/UrlInterpolationService.ts');
var oppia = require('AppInit.ts').module;
oppia.directive('outcomeFeedbackEditor', [
    'SubtitledHtmlObjectFactory', 'UrlInterpolationService',
    function (SubtitledHtmlObjectFactory, UrlInterpolationService) {
        return {
            restrict: 'E',
            scope: {},
            bindToController: {
                outcome: '='
            },
            templateUrl: UrlInterpolationService.getDirectiveTemplateUrl('/components/state-directives/outcome-editor/' +
                'outcome-feedback-editor.directive.html'),
            controllerAs: '$ctrl',
            controller: [function () {
                    var ctrl = this;
                    ctrl.OUTCOME_FEEDBACK_SCHEMA = {
                        type: 'html'
                    };
                }]
        };
    }
]);
