// Copyright 2014 The Oppia Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS-IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
/**
 * @fileoverview Utility services for explorations which may be shared by both
 * the learner and editor views.
 */
require('filters/string-utility-filters/camel-case-to-hyphens.filter.ts');
// Service for assembling extension tags (for interactions).
var oppia = require('AppInit.ts').module;
oppia.factory('ExtensionTagAssemblerService', [
    '$filter', 'HtmlEscaperService', function ($filter, HtmlEscaperService) {
        return {
            formatCustomizationArgAttrs: function (element, customizationArgSpecs) {
                for (var caSpecName in customizationArgSpecs) {
                    var caSpecValue = customizationArgSpecs[caSpecName].value;
                    element.attr($filter('camelCaseToHyphens')(caSpecName) + '-with-value', HtmlEscaperService.objToEscapedJson(caSpecValue));
                }
                return element;
            }
        };
    }
]);
