// Copyright 2016 The Oppia Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS-IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
/**
 * @fileoverview Controller for the supplemental card.
 */
require('domain/utilities/UrlInterpolationService.ts');
require('pages/exploration-player-page/services/' +
    'audio-translation-manager.service.ts');
require('pages/exploration-player-page/services/current-interaction.service.ts');
require('pages/exploration-player-page/exploration-player-page.constants.ts');
require('services/AudioPlayerService.ts');
require('services/AutogeneratedAudioPlayerService.ts');
require('services/contextual/WindowDimensionsService.ts');
require('pages/exploration-player-page/exploration-player-page.constants.ts');
var oppia = require('AppInit.ts').module;
oppia.directive('supplementalCard', [
    'UrlInterpolationService', function (UrlInterpolationService) {
        return {
            restrict: 'E',
            scope: {
                onClickContinueButton: '&',
                isLearnAgainButton: '&',
                getDisplayedCard: '&displayedCard',
            },
            templateUrl: UrlInterpolationService.getDirectiveTemplateUrl('/pages/exploration-player-page/learner-experience/' +
                'supplemental-card.directive.html'),
            controller: [
                '$scope', '$timeout', '$window',
                'WindowDimensionsService', 'CONTENT_FOCUS_LABEL_PREFIX',
                'TWO_CARD_THRESHOLD_PX', 'EVENT_ACTIVE_CARD_CHANGED',
                'CONTINUE_BUTTON_FOCUS_LABEL', 'AudioTranslationManagerService',
                'AudioPlayerService', 'AutogeneratedAudioPlayerService',
                'COMPONENT_NAME_FEEDBACK', 'AUDIO_HIGHLIGHT_CSS_CLASS',
                'CurrentInteractionService',
                function ($scope, $timeout, $window, WindowDimensionsService, CONTENT_FOCUS_LABEL_PREFIX, TWO_CARD_THRESHOLD_PX, EVENT_ACTIVE_CARD_CHANGED, CONTINUE_BUTTON_FOCUS_LABEL, AudioTranslationManagerService, AudioPlayerService, AutogeneratedAudioPlayerService, COMPONENT_NAME_FEEDBACK, AUDIO_HIGHLIGHT_CSS_CLASS, CurrentInteractionService) {
                    var updateDisplayedCard = function () {
                        $scope.displayedCard = $scope.getDisplayedCard();
                        $scope.clearHelpCard();
                        $scope.lastAnswer = null;
                        if ($scope.displayedCard.isCompleted()) {
                            $scope.lastAnswer = $scope.displayedCard.getLastAnswer();
                        }
                    };
                    $scope.OPPIA_AVATAR_IMAGE_URL = (UrlInterpolationService.getStaticImageUrl('/avatar/oppia_avatar_100px.svg'));
                    $scope.CONTINUE_BUTTON_FOCUS_LABEL = CONTINUE_BUTTON_FOCUS_LABEL;
                    $scope.helpCardHtml = null;
                    $scope.helpCardHasContinueButton = false;
                    $scope.windowDimensionsService = WindowDimensionsService;
                    // We use the max because the height property of the help card is
                    // unstable while animating, causing infinite digest errors.
                    var maxHelpCardHeightSeen = 0;
                    $scope.clearHelpCard = function () {
                        $scope.helpCardHtml = null;
                        $scope.helpCardHasContinueButton = false;
                        maxHelpCardHeightSeen = 0;
                    };
                    $scope.isHelpCardTall = function () {
                        var helpCard = $('.conversation-skin-help-card');
                        if (helpCard.height() > maxHelpCardHeightSeen) {
                            maxHelpCardHeightSeen = helpCard.height();
                        }
                        return maxHelpCardHeightSeen > $(window).height() - 100;
                    };
                    $scope.getHelpCardBottomPosition = function () {
                        var helpCard = $('.conversation-skin-help-card');
                        var container = $('.conversation-skin-supplemental-card-container');
                        return Math.max(container.height() - helpCard.height() / 2, 0);
                    };
                    CurrentInteractionService.registerPresubmitHook(function () {
                        // Do not clear the help card or submit an answer if there is an
                        // upcoming card.
                        if ($scope.displayedCard.isCompleted()) {
                            return;
                        }
                        $scope.clearHelpCard();
                    });
                    $scope.$on(EVENT_ACTIVE_CARD_CHANGED, function () {
                        updateDisplayedCard();
                    });
                    $scope.$on('helpCardAvailable', function (event, helpCard) {
                        $scope.helpCardHtml = helpCard.helpCardHtml;
                        $scope.helpCardHasContinueButton = helpCard.hasContinueButton;
                    });
                    $scope.getFeedbackAudioHighlightClass = function () {
                        if (AudioTranslationManagerService
                            .getCurrentComponentName() ===
                            COMPONENT_NAME_FEEDBACK &&
                            (AudioPlayerService.isPlaying() ||
                                AutogeneratedAudioPlayerService.isPlaying())) {
                            return AUDIO_HIGHLIGHT_CSS_CLASS;
                        }
                    };
                    updateDisplayedCard();
                }
            ]
        };
    }
]);
