// Copyright 2019 The Oppia Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS-IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
/**
 * @fileoverview TruncateInputBasedOnInteractionAnswerType filter for Oppia.
 */
require('filters/string-utility-filters/truncate.filter.ts');
/* Filter that trucates the input answer based on interaction type.
 * @param {string} input - The answer to truncate.
 * @param {string} interactionId - Interaction for which answer is to be
    truncated.
 * @param {integer} length - Truncated length of answer.
 */
var oppia = require('AppInit.ts').module;
oppia.filter('truncateInputBasedOnInteractionAnswerType', [
    '$filter', 'INTERACTION_SPECS', function ($filter, INTERACTION_SPECS) {
        return function (input, interactionId, length) {
            var answerType = INTERACTION_SPECS[interactionId].answer_type;
            var actualInputToTruncate = '';
            if (answerType === 'NormalizedString') {
                actualInputToTruncate = input;
            }
            else if (answerType === 'CodeEvaluation') {
                actualInputToTruncate = input.code;
            }
            else {
                throw Error('Unknown interaction answer type');
            }
            return $filter('truncate')(actualInputToTruncate, length);
        };
    }
]);
