// Copyright 2014 The Oppia Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS-IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
/**
 * @fileoverview Validator service for the interaction.
 */
require('interactions/baseInteractionValidationService.ts');
var oppia = require('AppInit.ts').module;
oppia.factory('ContinueValidationService', [
    'baseInteractionValidationService', 'WARNING_TYPES',
    function (baseInteractionValidationService, WARNING_TYPES) {
        return {
            getCustomizationArgsWarnings: function (customizationArgs) {
                var warningsList = [];
                baseInteractionValidationService.requireCustomizationArguments(customizationArgs, ['buttonText']);
                if (customizationArgs.buttonText.value.length === 0) {
                    warningsList.push({
                        type: WARNING_TYPES.CRITICAL,
                        message: 'The button text should not be empty.'
                    });
                }
                return warningsList;
            },
            getAllWarnings: function (stateName, customizationArgs, answerGroups, defaultOutcome) {
                var warningsList = this.getCustomizationArgsWarnings(customizationArgs);
                if (answerGroups.length > 0) {
                    warningsList.push({
                        type: WARNING_TYPES.CRITICAL,
                        message: ('Only the default outcome is necessary for a continue' +
                            ' interaction.')
                    });
                }
                if (!defaultOutcome || defaultOutcome.isConfusing(stateName)) {
                    warningsList.push({
                        type: WARNING_TYPES.ERROR,
                        message: ('Please specify what Oppia should do after the button' +
                            ' is clicked.')
                    });
                }
                return warningsList;
            }
        };
    }
]);
