// Copyright 2014 The Oppia Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS-IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
/**
 * @fileoverview Object factory for creating stopwatches.
 */
// A simple service that provides stopwatch instances. Each stopwatch can be
// independently reset and queried for the current time.
var oppia = require('AppInit.ts').module;
oppia.factory('StopwatchObjectFactory', ['$log', function ($log) {
        var Stopwatch = function () {
            this.startTime = null;
        };
        Stopwatch.prototype = {
            _getCurrentTime: function () {
                return Date.now();
            },
            reset: function () {
                this.startTime = this._getCurrentTime();
            },
            getTimeInSecs: function () {
                if (this.startTime === null) {
                    $log.error('Tried to retrieve the elapsed time, but no start time was set.');
                    return null;
                }
                return (this._getCurrentTime() - this.startTime) / 1000;
            }
        };
        return {
            create: function () {
                return new Stopwatch();
            }
        };
    }]);
