// Copyright 2019 The Oppia Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS-IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
/**
 * @fileoverview NormalizeWhitespace filter for Oppia.
 */
require('services/UtilsService.ts');
// Filter that removes whitespace from the beginning and end of a string, and
// replaces interior whitespace with a single space character.
var oppia = require('AppInit.ts').module;
oppia.filter('normalizeWhitespace', ['UtilsService', function (UtilsService) {
        return function (input) {
            if (UtilsService.isString(input)) {
                // Remove whitespace from the beginning and end of the string, and
                // replace interior whitespace with a single space character.
                input = input.trim();
                input = input.replace(/\s{2,}/g, ' ');
                return input;
            }
            else {
                return input;
            }
        };
    }]);
