// Copyright 2019 The Oppia Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS-IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
/**
 * @fileoverview Requires for NumericInput interaction.
 */
require('interactions/NumericInput/directives/NumericInputRulesService.ts');
require('interactions/NumericInput/directives/NumericInputValidationService.ts');
require('interactions/NumericInput/directives/' +
    'OppiaInteractiveNumericInputDirective.ts');
require('interactions/NumericInput/directives/OppiaResponseNumericInputDirective.ts');
require('interactions/NumericInput/directives/' +
    'OppiaShortResponseNumericInputDirective.ts');
