// Copyright 2014 The Oppia Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS-IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
/**
 * @fileoverview Service for recommending explorations at the end of an
 * exploration.
 */
require('services/ContextService.ts');
require('services/contextual/UrlService.ts');
var oppia = require('AppInit.ts').module;
oppia.factory('ExplorationRecommendationsService', [
    '$http', 'ContextService', 'UrlService', 'EXPLORATION_EDITOR_TAB_CONTEXT',
    'PAGE_CONTEXT',
    function ($http, ContextService, UrlService, EXPLORATION_EDITOR_TAB_CONTEXT, PAGE_CONTEXT) {
        var isIframed = UrlService.isIframed();
        var isInEditorPage = (ContextService.getPageContext() === PAGE_CONTEXT.EXPLORATION_EDITOR);
        var isInEditorPreviewMode = isInEditorPage && (ContextService.getEditorTabContext() ===
            EXPLORATION_EDITOR_TAB_CONTEXT.PREVIEW);
        var explorationId = ContextService.getExplorationId();
        return {
            getRecommendedSummaryDicts: function (authorRecommendedExpIds, includeAutogeneratedRecommendations, successCallback) {
                var recommendationsUrlParams = {
                    stringified_author_recommended_ids: JSON.stringify(authorRecommendedExpIds),
                    collection_id: null,
                    include_system_recommendations: null
                };
                if (GLOBALS.collectionId) {
                    recommendationsUrlParams.collection_id = GLOBALS.collectionId;
                }
                if (includeAutogeneratedRecommendations && !isInEditorPage) {
                    recommendationsUrlParams.include_system_recommendations = 'true';
                }
                $http.get('/explorehandler/recommendations/' + explorationId, {
                    params: recommendationsUrlParams
                }).then(function (response) {
                    successCallback(response.data.summaries);
                });
            }
        };
    }
]);
