// Copyright 2014 The Oppia Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS-IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
/**
 * @fileoverview A service that maintains the current set of parameters for the
 * learner.
 */
var oppia = require('AppInit.ts').module;
oppia.factory('LearnerParamsService', [function () {
        var _paramDict = {};
        return {
            // TODO(sll): Forbid use of 'answer', 'choices' as possible keys.
            init: function (initParamSpecs) {
                // The initParamSpecs arg is a dict mapping the parameter names used in
                // the exploration to their default values.
                _paramDict = angular.copy(initParamSpecs);
            },
            getValue: function (paramName) {
                if (!_paramDict.hasOwnProperty(paramName)) {
                    throw 'Invalid parameter name: ' + paramName;
                }
                else {
                    return angular.copy(_paramDict[paramName]);
                }
            },
            setValue: function (paramName, newParamValue) {
                // TODO(sll): Currently, all parameters are strings. In the future, we
                // will need to maintain information about parameter types.
                if (!_paramDict.hasOwnProperty(paramName)) {
                    throw 'Cannot set unknown parameter: ' + paramName;
                }
                else {
                    _paramDict[paramName] = String(newParamValue);
                }
            },
            getAllParams: function () {
                return angular.copy(_paramDict);
            }
        };
    }]);
