// Copyright 2014 The Oppia Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS-IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
/**
 * @fileoverview Service for showing the hint and solution modals.
 */
require('domain/utilities/UrlInterpolationService.ts');
require('pages/exploration-player-page/services/' +
    'audio-translation-manager.service.ts');
require('pages/exploration-player-page/services/' +
    'hints-and-solution-manager.service.ts');
require('pages/exploration-player-page/exploration-player-page.constants.ts');
require('pages/exploration-player-page/services/player-position.service.ts');
require('pages/exploration-player-page/services/player-transcript.service.ts');
require('services/AudioPlayerService.ts');
require('services/AutogeneratedAudioPlayerService.ts');
require('pages/exploration-player-page/exploration-player-page.constants.ts');
var oppia = require('AppInit.ts').module;
oppia.factory('HintAndSolutionModalService', [
    '$uibModal', 'AudioPlayerService',
    'AudioTranslationManagerService',
    'AutogeneratedAudioPlayerService',
    'HintsAndSolutionManagerService', 'PlayerPositionService',
    'PlayerTranscriptService', 'UrlInterpolationService', 'COMPONENT_NAME_HINT',
    'COMPONENT_NAME_SOLUTION', 'EVENT_AUTOPLAY_AUDIO',
    function ($uibModal, AudioPlayerService, AudioTranslationManagerService, AutogeneratedAudioPlayerService, HintsAndSolutionManagerService, PlayerPositionService, PlayerTranscriptService, UrlInterpolationService, COMPONENT_NAME_HINT, COMPONENT_NAME_SOLUTION, EVENT_AUTOPLAY_AUDIO) {
        return {
            displayHintModal: function (index) {
                return $uibModal.open({
                    templateUrl: UrlInterpolationService.getDirectiveTemplateUrl('/pages/exploration-player-page/templates/' +
                        'hint-and-solution-modal.template.html'),
                    backdrop: 'static',
                    controller: [
                        '$scope', '$rootScope', '$uibModalInstance',
                        function ($scope, $rootScope, $uibModalInstance) {
                            $scope.isHint = true;
                            $scope.hint = HintsAndSolutionManagerService.displayHint(index);
                            var displayedCard = PlayerTranscriptService.getCard(PlayerPositionService.getDisplayedCardIndex());
                            var recordedVoiceovers = displayedCard.getRecordedVoiceovers();
                            var hintContentId = $scope.hint.getContentId();
                            AudioTranslationManagerService.setSecondaryAudioTranslations(recordedVoiceovers.getBindableVoiceovers(hintContentId), $scope.hint.getHtml(), COMPONENT_NAME_HINT);
                            $rootScope.$broadcast(EVENT_AUTOPLAY_AUDIO);
                            $scope.closeModal = function () {
                                AudioPlayerService.stop();
                                AutogeneratedAudioPlayerService.cancel();
                                AudioTranslationManagerService
                                    .clearSecondaryAudioTranslations();
                                $uibModalInstance.dismiss('cancel');
                            };
                        }
                    ]
                });
            },
            displaySolutionModal: function () {
                return $uibModal.open({
                    templateUrl: UrlInterpolationService.getDirectiveTemplateUrl('/pages/exploration-player-page/templates/' +
                        'hint-and-solution-modal.template.html'),
                    backdrop: 'static',
                    controller: [
                        '$scope', '$rootScope', '$uibModalInstance',
                        function ($scope, $rootScope, $uibModalInstance) {
                            $scope.isHint = false;
                            var solution = HintsAndSolutionManagerService.displaySolution();
                            var solutionContentId = solution.explanation.getContentId();
                            var displayedCard = PlayerTranscriptService.getCard(PlayerPositionService.getDisplayedCardIndex());
                            var recordedVoiceovers = displayedCard.getRecordedVoiceovers();
                            AudioTranslationManagerService.setSecondaryAudioTranslations(recordedVoiceovers.getBindableVoiceovers(solutionContentId), solution.explanation.getHtml(), COMPONENT_NAME_SOLUTION);
                            $rootScope.$broadcast(EVENT_AUTOPLAY_AUDIO);
                            var interaction = displayedCard.getInteraction();
                            $scope.shortAnswerHtml = solution.getOppiaShortAnswerResponseHtml(interaction);
                            $scope.solutionExplanationHtml =
                                solution.getOppiaSolutionExplanationResponseHtml();
                            $scope.closeModal = function () {
                                AudioPlayerService.stop();
                                AutogeneratedAudioPlayerService.cancel();
                                AudioTranslationManagerService
                                    .clearSecondaryAudioTranslations();
                                $uibModalInstance.dismiss('cancel');
                            };
                        }
                    ]
                });
            },
            displaySolutionInterstitialModal: function () {
                return $uibModal.open({
                    templateUrl: UrlInterpolationService.getDirectiveTemplateUrl('/pages/exploration-player-page/templates/' +
                        'solution-interstitial-modal.template.html'),
                    backdrop: 'static',
                    controller: [
                        '$scope', '$uibModalInstance',
                        function ($scope, $uibModalInstance) {
                            $scope.continueToSolution = function () {
                                $uibModalInstance.close();
                            };
                            $scope.cancel = function () {
                                $uibModalInstance.dismiss('cancel');
                            };
                        }
                    ]
                });
            }
        };
    }
]);
