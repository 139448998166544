// Copyright 2019 The Oppia Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS-IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
/**
 * @fileoverview FormatTimer filter for Oppia.
 */
var oppia = require('AppInit.ts').module;
oppia.filter('formatTimer', [function () {
        return function (input) {
            var formatNum = function (n) {
                return (n < 10 ? '0' : '') + n;
            };
            var seconds = input % 60;
            var minutes = Math.floor(input / 60);
            return (formatNum(minutes) + ':' + formatNum(seconds));
        };
    }]);
