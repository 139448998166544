// Copyright 2014 The Oppia Authors. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS-IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
/**
 * @fileoverview Directive for displaying the list of threads in the feedback
 * tab of the exploration editor.
 */
require('filters/string-utility-filters/truncate.filter.ts');
require('domain/utilities/UrlInterpolationService.ts');
require('pages/exploration-editor-page/feedback-tab/services/' +
    'thread-status-display.service.ts');
require('services/DateTimeFormatService.ts');
var oppia = require('AppInit.ts').module;
oppia.directive('threadTable', [
    'UrlInterpolationService', function (UrlInterpolationService) {
        return {
            restrict: 'E',
            scope: {
                onClickRow: '=',
                getThreads: '&threads'
            },
            templateUrl: UrlInterpolationService.getDirectiveTemplateUrl('/pages/exploration-editor-page/feedback-tab/thread-table/' +
                'thread-table.directive.html'),
            controller: [
                '$scope', 'ThreadStatusDisplayService', 'DateTimeFormatService',
                function ($scope, ThreadStatusDisplayService, DateTimeFormatService) {
                    $scope.getLabelClass = ThreadStatusDisplayService.getLabelClass;
                    $scope.getHumanReadableStatus = (ThreadStatusDisplayService.getHumanReadableStatus);
                    $scope.getLocaleAbbreviatedDatetimeString = (DateTimeFormatService.getLocaleAbbreviatedDatetimeString);
                }
            ]
        };
    }
]);
